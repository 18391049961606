* {
  min-height: 0;
  min-width: 0;
}
.login-img-section {
  max-width: 590px;
  min-width: 590px;
  position: relative;
  /* background: linear-gradient(
    46.08deg,
    #131517 5.29%,
    #0d0d0de6 75.75%
  ); */
}
.login-img {
  width: 100%;
  height: 99.5%;
  object-fit: cover;
  opacity: 0.1;
}
.login-text {
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
}
.founder-text {
  color: #ffffff;
  font-size: 24px;
  line-height: 32px;
}
.signup-text {
  font-size: 24px;
  line-height: 32px;
}
.loginButton {
  font-size: 16px;
  line-height: 22px;
  /* background-color: #15191c; */
  /* color: white; */
  border: none;
  padding: 14px 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.login-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #0d0d0d;
}
.section-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #8A96A6;
  font-size: 12px;
  padding-top: 48px;
  padding-bottom: 36px;
  width: 100%;
  justify-content: center;
  white-space: nowrap; /* Prevents the text from breaking into multiple lines */
}
.no-span:before,
.no-span:after {
  border-top: 2px solid #131517;
  content: "";
  flex-grow: 1;
  margin: 0 10px; /* Space between text and lines */
  /* max-width: 230px; */
  max-width: calc((590px - 70px) / 2);
}

.no-span:before {
  right: 1.5%;
}

.no-span:after {
  left: 1.5%;
}

.MuiGrid-item .stdropdown-container .stsearch-box {
  padding: 0;
}

.MuiGrid-item .stdropdown-container .stsearch-box input:focus-visible {
  outline: none;
}

.custom_calendar-view .toastui-calendar-layout {
  background: transparent !important;
}

.custom_calendar-view .toastui-calendar-layout .toastui-calendar-weekday-grid {
  border: 0 !important;
}

.toastui-calendar-day-name-container .toastui-calendar-template-monthDayName {
  text-align: center;
  font-size: 14px;
  color: #8a96a6;
  font-weight: 500;
  line-height: 20px;
}

/* .custom_calendar-view .toastui-calendar-layout .toastui-calendar-daygrid-cell {
  border: 1px solid #212529 !important;
  border-radius: 12px;
  margin: 8px;
  background: #131517 !important;
  width: 19% !important;
  height: 90% !important;
} */

.custom_calendar-view
  .toastui-calendar-layout
  .toastui-calendar-daygrid-cell
  .toastui-calendar-grid-cell-header {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #8a96a6;
}

.custom_calendar-view .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
  background-color: #4ed5ff;
}

.custom_calendar-view .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
  color: #ffffff;
}

.custom_calendar-view
  .toastui-calendar-layout
  .toastui-calendar-daygrid-cell
  .toastui-calendar-grid-cell-header
  .toastui-calendar-grid-cell-date {
  color: #8a96a6 !important;
  border: none;
  cursor: pointer;
}

.custom-event {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.custom-event-title {
  font-size: 20px;
  color: #00ff00; /* Green color for the title */
}

.custom-event-trades {
  font-size: 12px;
  color: #888888;
}
.sidebar {
  color: white;
  /* height: 100vh; */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  /* padding: 14px; */
  transition: 0.2s ease-in-out;
}
.sidebar li:hover {
  /* padding: 14px; */
  background-color: #212529;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
}

.sidebar a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
}
.sidebar li.with-border {
  border-bottom: 1px solid #555;
}

.toastui-calendar-weekday-event {
  height: 80px !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.toastui-calendar-weekday-event .toastui-calendar-weekday-event-dot {
  opacity: 0;
}

.toastui-calendar-weekday-event .toastui-calendar-weekday-event-title .toastui-calendar-template-time {
  text-align: end;
  padding-right: 10px;
}

.toastui-calendar-weekday-event .toastui-calendar-weekday-event-title p {
  color: #21FF90 !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.toastui-calendar-weekday-event .toastui-calendar-weekday-event-title p.minus {
  color: #FF4F6F !important;
}

.toastui-calendar-weekday-event .toastui-calendar-weekday-event-title span {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #8A96A6 !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}
.custom-input input{
  padding: 14px !important;
}

.profile-image {
  width: 100%;
  min-width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #4ed5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  position: relative;
}

.profile-image .initials {
  text-transform: uppercase;
}

p.MuiFormHelperText-root {
  color: #d32f2f;
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active {
   background-color: transparent !important;
   box-shadow: none !important;
   transition: background-color 0s 600000s, color 0s 600000s !important;
}

.apexcharts-legend-series{
  gap: 6px;
}
.overlay {
  position: absolute;
  background: linear-gradient(180deg, #131517 0%, #131517 100%);
  opacity: 0.5;
  z-index: 1000;
  min-height: 200px;
  min-width: 200px;
  bottom: 0;
  right: 0;
}

.slick-list .slick-track {
  margin-left: 0;
  margin-right: 0;
}